export const openFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
};

export const scaleElementToFS = (element) => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const w_scale = vw / 1920;
  const h_scale = vh / 1080;
  const scale = Math.min(h_scale, w_scale);
  console.log("SCALE: ", scale);
  element.style.transform = `scale(${scale})`;

  document.body.style.height = "100%";
  document.body.style.width = "100%";
};
