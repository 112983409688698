import Peer from "peerjs";
import { backOff } from "exponential-backoff";
import { scaleElementToFS } from "./utils";

const roomId =
  new URLSearchParams(window.location.search).get("roomId") || "default";

const sender =
  new URLSearchParams(window.location.search).get("sender") !== null;
console.debug(`Loading app as ${sender ? "SENDER" : "RECEIVER"}`);

const mainVideoElem = document.getElementById("main_video");
const startButton = document.getElementById("start_button");

const PEERJS_OPTIONS = {
  host: "magicpeerjs.liorsbg.repl.co",
  // port: 80,
  key: "vivaciousvikings",
  debug: 3,
  config: {
    iceServers: [
      {
        urls: ["stun:stun3.l.google.com:19302", "stun:stun.vidyo.com:3478"],
      },
    ],
  } /* TODO: Add TURN server */,
};

const DISPLAY_MEDIA_CONSTRAINTS = {
  width: 1920,
  height: 1080,
  frameRate: { min: 25, max: 60 },
  aspectRatio: 1.7777777778,
  resizeMode: "crop-and-scale",
  displaySurface: "browser",
  logicalSurface: true,
  cursor: "never",
};

const SENDER_ID = `lior-${roomId}-sender`;

const getActivePeers = (connections) => {
  return Object.values(connections)
    .flat()
    .filter((c) =>
      ["checking", "completed", "connected", "new"].includes(
        // Use iceConnectionState and not connection because of Firefox support
        c.peerConnection.iceConnectionState
      )
    );
};

if (sender) {
  const senderPeer = new Peer(SENDER_ID, PEERJS_OPTIONS);
  console.debug(`Creating peer with id ${SENDER_ID}`);

  startButton.style.display = "block";
  let streamPromise;
  startButton.onclick = () => {
    startButton.style.display = "none";
    streamPromise = navigator.mediaDevices.getDisplayMedia(
      DISPLAY_MEDIA_CONSTRAINTS
    );
  };

  // When someone connects to the sender, respond with screen capture
  senderPeer.on("connection", (dataConnection) => {
    console.debug(`Incoming connection, Calling...`);

    streamPromise
      .then((stream) => {
        const mediaConnection = senderPeer.call(dataConnection.peer, stream); // Answer the call with an A/V stream.
        // mediaConnection.peerConnection.oniceconnectionstatechange((ev) => {
        mediaConnection.on("iceStateChanged", (iceConnectionState) => {
          // Possible states: type RTCIceConnectionState = "checking" | "closed" | "completed" | "connected" | "disconnected" | "failed" | "new";
          if (
            ["disconnected", "failed", "closed"].includes(iceConnectionState)
          ) {
            console.debug(`Peer iceState changed to ${iceConnectionState}`);
            // Check if everyone left
            // if (getActivePeers(senderPeer.connections).length === 0) {
            setTimeout(() => {
              console.debug("Verifiying empty room");
              if (getActivePeers(senderPeer.connections).length === 0) {
                console.error("EVERYONE'S GONE!!! Closing streamer");
              }
            }, 10000);
            // }
          }
        });
      })
      .catch((err) => {
        console.error("Failed initiating call", err);
      });
  });

  senderPeer.on("disconnected", () => {
    console.debug("Disconnected, will try to reconnect");
    backOff(
      () => {
        senderPeer.reconnect();
        return new Promise((resolve) => {
          senderPeer.on("open", () => {
            console.debug("Reconnected!");
            resolve();
          });
        });
      },
      {
        retry: (e, attemptNumber) => {
          console.debug(`Retrying to reconnect, attempt ${attemptNumber}`);
        },
      }
    );
  });
} else {
  // RECEIVER
  const receiverPeer = new Peer(PEERJS_OPTIONS);

  receiverPeer.on("open", (id) => {
    console.debug(`Connected to PeerJS Server with id: ${id}`);
    // Use data channel to connect to sender, this will trigger a call back
    console.debug(`Trying to connect to sender`);
    const dataConnection = receiverPeer.connect(SENDER_ID);
  });

  // If we receive a call, show the stream
  receiverPeer.on("call", (mediaConnection) => {
    mediaConnection.answer();
    console.debug("Receiving call...");
    mediaConnection.on("stream", (stream) => {
      console.debug("Setting video element srcObject to received stream");
      mainVideoElem.srcObject = stream;
      mainVideoElem.style.display = "block";
    });
  });
}
